import * as React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { StaticImage } from "gatsby-plugin-image";

// Import styles
import "./styles/team.scss";

// Bootstrap imports
import { Button, Card, Col, Container, Row } from "react-bootstrap";

const Team = () => {
  // Wordpress Query
  const data = useStaticQuery(graphql`
    {
      allWpTeamMember(sort: { fields: date, order: DESC }) {
        nodes {
          excerpt
          featuredImage {
            node {
              altText
              id
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG, formats: AUTO)
                }
              }
            }
          }
          id
          slug
          teamMemberFields {
            jobTitle
          }
          title
        }
      }
    }
  `);

  // Wordpress data
  const { allWpTeamMember } = data;

  return (
    <div className="team">
      <Container className="fade-in-slow">
        <Row>
          {allWpTeamMember.nodes.map((person) => {
            return (
              <Col className="column" xs={12} sm={6} lg={4}>
                <Card>
                  {person.featuredImage ? (
                    <GatsbyImage
                      className="slide-image"
                      image={
                        person.featuredImage.node.localFile.childImageSharp
                          .gatsbyImageData
                      }
                      alt={person.featuredImage.node.altText}
                    />
                  ) : (
                    <StaticImage
                      className="slide-image"
                      src="../assets/images/placeholder.png"
                      alt="The Adaptix logo."
                      placeholder="tracedSVG"
                    />
                  )}
                  <Card.Body>
                    <h3 className="yell">{person.title}</h3>
                    <p className="talk">{person.teamMemberFields.jobTitle}</p>
                    <Link to={person.slug}>
                      <Button variant="accent">Read More</Button>
                    </Link>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default Team;
