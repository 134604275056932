import * as React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

// Import layout
import Layout from "../layouts/main";

// Import components
import MetaData from "../components/MetaData";
import Team from "../components/Team";

// Import styles
import "./styles/staff.scss";

// Import assets
import image from "../assets/images/og-image.jpg";

// Icons
import { FaUsers } from "react-icons/fa";

// Bootstrap imports
import { Button, Card, Col, Container, Row } from "react-bootstrap";

const StaffPage = () => {
  // Wordpress Query
  const data = useStaticQuery(graphql`
    {
      wpPage(title: { eq: "Staff" }) {
        id
        staffFields {
          title
          backgroundImage {
            altText
            localFile {
              url
            }
          }
        }
        seoFields {
          seo {
            title
            description
          }
        }
      }
    }
  `);

  // Wordpress data
  const { wpPage } = data;

  // Background image
  let backgroundImage = {
    backgroundImage: `url(${wpPage.staffFields.backgroundImage.localFile.url})`,
  };

  return (
    <Layout>
      <MetaData
        title={wpPage.seoFields.seo.title}
        description={wpPage.seoFields.seo.description}
        image={`https://mountaineer-dental.netlify.app${image}`}
      />
      <div className="staff-page">
        <div className="staff-intro fade-in-slow" style={backgroundImage} />
        <div className="overlay" />
        <div className="title">
          <div className="background-shape" />
          <Container className="fade-in-slow">
            <h1 className="shout">
              <FaUsers />
              {wpPage.staffFields.title}
            </h1>
          </Container>
        </div>
        <Team />
      </div>
    </Layout>
  );
};

export default StaffPage;
